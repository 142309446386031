import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRole {
  @Input()
  role: 'owner' | 'admin' | 'member';
}
