import { MonthlyYearly } from '@shared/models/billing.model';
import { PlanCustomization, PlanInfo } from '@plans/shared/models/plans.model';
import { DeleteDataAnswer } from '@plans/shared/models/plans.model';
import { PlanStateModel, UpdateFrom } from '@shared/states/plan.state';

export class SetPlanState {
  static readonly type = '[Plans] Set Plan state';

  constructor(readonly state: PlanStateModel) {}
}

export class GetPlans {
  static readonly type = '[Plans] Get Plans';
}

export class GetOptions {
  static readonly type = '[Plans] Get Options';
}

export class UpdateChosenPlan {
  static readonly type = '[Plans] Update Chosen Plan';

  constructor(readonly chosenPlan: PlanInfo) {}
}

export class ClearChosen {
  static readonly type = '[Plans] Clear chosen';
}

export class UpdateChosenOption {
  static readonly type = '[Plans] Update Chosen Options';

  constructor(readonly option: PlanCustomization) {}
}

export class UpdateChosenBillingPeriod {
  static readonly type = '[Plans] Update Chosen Billing Period';

  constructor(readonly period: MonthlyYearly) {}
}

export class DowngradeDelete {
  static readonly type = '[Plans] Downgrade Delete';

  constructor(readonly response: DeleteDataAnswer) {}
}

export class CheckUpgradeCheaper {
  static readonly type = '[Plans] Check Upgrade Cheaper';
}

export class GetPlanPrice {
  static readonly type = '[Plans] Get Plan Price';

  constructor(readonly updateFrom: UpdateFrom) {}
}
