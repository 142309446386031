import * as i0 from '@angular/core';
import { ɵisPromise, NgZone, ɵɵdirectiveInject, INJECTOR, ɵglobal, NgModule } from '@angular/core';
import { Store } from '@ngxs/store';
import { isObservable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
function unwrapObservable(store, wrapped, actionCompleter) {
  if (actionCompleter !== null) {
    wrapped = wrapped.pipe(takeUntil(actionCompleter.cancelUncompleted$));
  }
  wrapped.subscribe({
    next: actionOrActions => store.dispatch(actionOrActions)
  });
  return wrapped;
}
function unwrapPromise(store, wrapped) {
  return wrapped.then(actionOrActions => {
    store.dispatch(actionOrActions);
    return actionOrActions;
  });
}
function unwrapAndDispatch(store, wrapped, actionCompleter) {
  if (ɵisPromise(wrapped)) {
    return unwrapPromise(store, wrapped);
  } else if (isObservable(wrapped)) {
    return unwrapObservable(store, wrapped, actionCompleter);
  } else {
    store.dispatch(wrapped);
    return wrapped;
  }
}
class NgxsDispatchPluginModuleNotImported extends Error {
  constructor() {
    super(...arguments);
    this.message = 'NgxsDispatchPluginModule is not imported';
  }
}
let _injector = null;
function setInjector(injector) {
  _injector = injector;
}
function getStore() {
  if (_injector === null) {
    throw new NgxsDispatchPluginModuleNotImported();
  } else {
    return _injector.get(Store);
  }
}
function getNgZone() {
  if (_injector === null) {
    throw new NgxsDispatchPluginModuleNotImported();
  } else {
    return _injector.get(NgZone);
  }
}
class ActionCompleter {
  constructor() {
    this.cancelUncompleted$ = new Subject();
  }
  cancelPreviousAction() {
    this.cancelUncompleted$.next();
  }
}
function createActionCompleter(cancelUncompleted) {
  return cancelUncompleted ? new ActionCompleter() : null;
}

// Angular doesn't export `NG_FACTORY_DEF`.
const NG_FACTORY_DEF = 'ɵfac';
// A `Symbol` which is used to save the `Injector` onto the class instance.
const InjectorInstance = Symbol('InjectorInstance');
// A `Symbol` which is used to determine if factory has been decorated previously or not.
const FactoryHasBeenDecorated = Symbol('FactoryHasBeenDecorated');
// eslint-disable-next-line @typescript-eslint/ban-types
function ensureLocalInjectorCaptured(target) {
  if (FactoryHasBeenDecorated in target.constructor.prototype) {
    return;
  }
  const constructor = target.constructor;
  // Means we're in AOT mode.
  if (typeof constructor[NG_FACTORY_DEF] === 'function') {
    decorateFactory(constructor);
  } else if (ngDevMode) {
    // We're running in JIT mode and that means we're not able to get the compiled definition
    // on the class inside the property decorator during the current message loop tick. We have
    // to wait for the next message loop tick. Note that this is safe since this Promise will be
    // resolved even before the `APP_INITIALIZER` is resolved.
    // The below code also will be executed only in development mode, since it's never recommended
    // to use the JIT compiler in production mode (by setting "aot: false").
    decorateFactoryLater(constructor);
  }
  target.constructor.prototype[FactoryHasBeenDecorated] = true;
}
function localInject(instance, token) {
  const injector = instance[InjectorInstance];
  return injector ? injector.get(token) : null;
}
function decorateFactory(constructor) {
  const factory = constructor[NG_FACTORY_DEF];
  if (typeof factory !== 'function') {
    return;
  }
  // Let's try to get any definition.
  // Caretaker note: this will be compatible only with Angular 9+, since Angular 9 is the first
  // Ivy-stable version. Previously definition properties were named differently (e.g. `ngComponentDef`).
  const def = constructor.ɵprov || constructor.ɵpipe || constructor.ɵcmp || constructor.ɵdir;
  const decoratedFactory = () => {
    const instance = factory();
    // Caretaker note: `inject()` won't work here.
    // We can use the `directiveInject` only during the component
    // construction, since Angular captures the currently active injector.
    // We're not able to use this function inside the getter (when the `selectorId` property is
    // requested for the first time), since the currently active injector will be null.
    instance[InjectorInstance] = ɵɵdirectiveInject(
    // We're using `INJECTOR` token except of the `Injector` class since the compiler
    // throws: `Cannot assign an abstract constructor type to a non-abstract constructor type.`.
    // Caretaker note: that this is the same way of getting the injector.
    INJECTOR);
    return instance;
  };
  if (def) {
    def.factory = decoratedFactory;
  }
  Object.defineProperty(constructor, NG_FACTORY_DEF, {
    get: () => decoratedFactory
  });
}
function decorateFactoryLater(constructor) {
  // This function actually will be tree-shaken away when building for production since it's guarded with `ngDevMode`.
  // We're having the `try-catch` here because of the `SyncTestZoneSpec`, which throws
  // an error when micro or macrotask is used within a synchronous test. E.g. `Cannot call
  // Promise.then from within a sync test`.
  try {
    Promise.resolve().then(() => {
      decorateFactory(constructor);
    });
  } catch {
    // This is kind of a "hack", but we try to be backwards-compatible,
    // tho this `catch` block will only be executed when tests are run with Jasmine or Jest.
    ɵglobal.process && ɵglobal.process.nextTick && ɵglobal.process.nextTick(() => {
      decorateFactory(constructor);
    });
  }
}
const defaultOptions = {
  cancelUncompleted: false
};
function Dispatch(options = defaultOptions) {
  return (
  // eslint-disable-next-line @typescript-eslint/ban-types
  target, propertyKey,
  // eslint-disable-next-line @typescript-eslint/ban-types
  descriptor) => {
    // eslint-disable-next-line @typescript-eslint/ban-types
    let originalValue;
    const actionCompleter = createActionCompleter(options.cancelUncompleted);
    function wrapped() {
      // Every time the function is invoked we have to generate event
      // to cancel previously uncompleted asynchronous job
      if (actionCompleter !== null) {
        actionCompleter.cancelPreviousAction();
      }
      const store = localInject(this, Store) || getStore();
      const ngZone = localInject(this, NgZone) || getNgZone();
      // eslint-disable-next-line prefer-rest-params
      const wrapped = originalValue.apply(this, arguments);
      return ngZone.runOutsideAngular(() => unwrapAndDispatch(store, wrapped, actionCompleter));
    }
    if (typeof descriptor?.value === 'function') {
      originalValue = descriptor.value;
      descriptor.value = wrapped;
    } else {
      Object.defineProperty(target, propertyKey, {
        set: value => originalValue = value,
        get: () => wrapped
      });
    }
    ensureLocalInjectorCaptured(target);
  };
}
class NgxsDispatchPluginModule {
  constructor(ngModuleRef) {
    setInjector(ngModuleRef.injector);
    ngModuleRef.onDestroy(() => {
      setInjector(null);
    });
  }
  static forRoot() {
    return {
      ngModule: NgxsDispatchPluginModule
    };
  }
}
/** @nocollapse */
NgxsDispatchPluginModule.ɵfac = function NgxsDispatchPluginModule_Factory(t) {
  return new (t || NgxsDispatchPluginModule)(i0.ɵɵinject(i0.NgModuleRef));
};
/** @nocollapse */
NgxsDispatchPluginModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxsDispatchPluginModule
});
/** @nocollapse */
NgxsDispatchPluginModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsDispatchPluginModule, [{
    type: NgModule
  }], function () {
    return [{
      type: i0.NgModuleRef
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Dispatch, NgxsDispatchPluginModule };
