import { BillingPlanData } from '@shared/models/billing.model';
import { PlanId } from '@shared/models/plan.model';
import { PlanInfo } from '@plans/shared/models/plans.model';

export function isSmsEnabled(plan: BillingPlanData | PlanInfo | PlanId): boolean {
  const planId: PlanId =
    typeof plan === 'string'
      ? plan
      : plan?.hasOwnProperty('id')
        ? (plan as PlanInfo).id
        : (plan as BillingPlanData)?.plan.id;

  return ['plan_genius', 'plan_master', 'plan_einstein', 'plan_pro', 'plan_enterprise', 'trial_plan'].includes(planId);
}
