import { DeleteDataAnswer } from '@plans/shared/models/plans.model';
import {
  BillingFeedback,
  BillingPlanData,
  ClientInfo,
  LicenceUnlockSettings,
  PaymentCard,
  SMSPackage,
} from '@shared/models/billing.model';

import { TrialExpiredResponse } from '@home/shared/dialogs/trial-expired/trial-expired.dialog';
import { PaymentFailedResponse } from '@home/shared/dialogs/payment-failed/payment-failed.dialog';

export class GetPaymentHistory {
  static readonly type = '[Billing] Get Payment History';
}

export class GetBuyerInfo {
  static readonly type = '[Billing] Get Buyer Info';
}

export class GetPaymentCard {
  static readonly type = '[Billing] Get Payment Card';
}

export class GetLocked {
  static readonly type = '[Billing] Get Locked';
}

export class GetActivePlan {
  static readonly type = '[Billing] Get Active Plan';
}

export class GetUsage {
  static readonly type = '[Billing] Get Usage';
}

export class GetLicenseUsage {
  static readonly type = '[Billing] Get License Usage';

  constructor(readonly teamKey: string | null) {}
}

export class TryFree {
  static readonly type = '[Billing] Try Free';
}

export class GoToFeedback {
  static readonly type = '[Billing] Go to feedback';

  constructor(readonly feedback: BillingFeedback) {}
}

export class DowngradeStatusRead {
  static readonly type = '[Billing] Downgrade Status read';
}

export class TrialStatusRead {
  static readonly type = '[Billing] Trial Status read';

  constructor(readonly response: TrialExpiredResponse) {}
}

export class PaymentStatusRead {
  static readonly type = '[Billing] Payment Status read';

  constructor(readonly response: PaymentFailedResponse) {}
}

export class CancelPlan {
  static readonly type = '[Billing] Cancel Plan';

  constructor(readonly response?: DeleteDataAnswer) {}
}

export class SetNewPlan {
  static readonly type = '[Billing] Set New Plan';

  constructor(readonly newPlan: BillingPlanData) {}
}

export class UpdateClientInfo {
  static readonly type = '[Billing] Update client info';

  constructor(readonly clientInfo: ClientInfo) {}
}

export class UpdateCardInfo {
  static readonly type = '[Billing] Update card info';

  constructor(readonly paymentMethod: PaymentCard) {}
}

export class GetInvoiceDetails {
  static readonly type = '[Billing] Get Invoice Details';
  constructor(readonly id: string) {}
}

export class GetSmsPackages {
  static readonly type = '[Billing] Get SMS Packages';
}

export class UnlockAnswers {
  static readonly type = '[Billing] Unlock Answers';

  readonly settings: LicenceUnlockSettings;

  constructor(settings: Omit<LicenceUnlockSettings, 'type'>) {
    this.settings = {
      type: 'answers',
      ...settings,
    };
  }
}

export class UnlockContacts {
  static readonly type = '[Billing] Unlock Contacts';

  readonly settings: LicenceUnlockSettings;

  constructor(settings: Omit<LicenceUnlockSettings, 'type'>) {
    this.settings = {
      type: 'contacts',
      ...settings,
    };
  }
}

export class DisableSMSAutoRefill {
  static readonly type = '[Billing] Disable SMS AutoRefill';
}

export class EnableSMSAutoRefill {
  static readonly type = '[Billing] Enable SMS AutoRefill';
  constructor(readonly smsPackage: SMSPackage) {}
}

export class RequestSCA {
  static readonly type = '[Billing] Request SCA';
}

export class VerifySCA {
  static readonly type = '[Billing] Verify SCA';
}

export class ExpiredSCA {
  static readonly type = '[Billing] Expired SCA';
}

export class ConfirmSCA {
  static readonly type = '[Billing] Confirm SCA';
  constructor(readonly scaToken: string) {}
}
