import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { defer, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { loadStripe } from '@stripe/stripe-js/pure';
import type { PaymentIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  readonly stripe: Observable<Stripe> = defer(() => loadStripe(environment.stripePublishableKey)).pipe(shareReplay(1));

  readonly elements: Observable<StripeElements> = defer(() =>
    this.stripe.pipe(map((stripe) => stripe.elements())),
  ).pipe(shareReplay(1));

  confirmCardPayment(scaToken: string): Observable<PaymentIntentResult> {
    return this.stripe.pipe(
      take(1),
      switchMap((stripe) => stripe.confirmCardPayment(scaToken)),
    );
  }
}
