import { DeleteDataAnswer, PlanCustomization, PlanInfo, PlanPayment } from '@plans/shared/models/plans.model';
import { Country } from '@shared/enums/countries.enum';
import { PlanId } from '@shared/models/plan.model';
import { SourceType } from '@shared/models/utility.model';

export type MonthlyYearly = 'monthly' | 'yearly';

export enum TrialStatus {
  OK,
  IN_TRIAL,
  TRIAL_EXPIRED,
  TRIAL_USED,
}

export enum PaymentStatus {
  OK,
  PAYMENT_FAILED,
}

export enum BillingStatus {
  OK,
  PARKED_PLAN,
  FORCED_FREE,
  SCA_REQUIRED,
  REVIEW_REQUIRED,
  FREE,
}

export enum UnlockStatus {
  CAN_UNLOCK_ALL = 'canUnlockAll',
  CAN_UNLOCK_SOME = 'canUnlockSome',
  CANNOT_UNLOCK_ANY = 'cannotUnlockAny',
}

export enum BillingFeedback {
  PlanUpgraded = 'planUpgraded',
  PlanParked = 'planParked',
  PlanChanged = 'planChanged',
}

export interface LicenceUnlockSettings {
  type: 'answers' | 'contacts';
  sourceType: SourceType;
  sourceKey: string;
  from?: 'licence' | 'extra';
}

export interface SurveyLocked {
  survey: string;
  reason: 1 | 2;
}

export interface LockedSurveyStatus {
  // https://storage.googleapis.com/developers.zeffi.dev/docs/api/index.html#api-License-LicenseSurveyStatuses
  surveyKey: string;
  answers: {
    licenseFull: number;
  };
  contacts: {
    licenseFull: number;
  };
}

export interface LockedResource {
  lockSource: string;
  sourceName?: string;
  sourceType: SourceType;
  lockedContacts: number;
  lockedAnswers: number;
  modified?: number;
  created?: number;
  owner?: string; // creator if no owner
}

export interface LockedSurveyDetails {
  surveyKey: string;
  surveyName: string;
  surveyLogo: string;
  surveyPublished: boolean;
  surveyOwner: string;
  lockedContacts: number;
  lockedAnswers: number;
  reasonContacts: 1 | 2;
  reasonAnswers: 1 | 2;
}

export interface BillingLicenseInfo {
  answers: number;
  contacts: number;
  emails: number;
}

export interface BillingPlanData {
  stripeId?: string;
  plan: PlanInfo;
  options: PlanCustomization[];
  paymentPlan: PlanPayment;
  parked?: boolean;
  removeData?: DeleteDataAnswer;
  upcoming?: BillingPlanData;
  canceled?: boolean;
  trialUsed?: boolean;
  forcedFree?: boolean;
  scaExpired?: boolean;
  scaRequired?: boolean;
  trialStatus?: TrialStatus;
  paymentStatus?: PaymentStatus;
  billingType?: 'manual' | 'automatic' | 'free';
  periodEnd?: number;
  license?: BillingLicenseInfo;
}

export interface ClientInfo {
  type?: 'company' | 'individual';
  name?: string;
  country?: keyof typeof Country;
  vatId?: string;
  taxPercentage?: number;
  taxName?: string;
  complete?: boolean;
}

export interface PaymentCard {
  cardToken: string;
  name: string;
  last4: string;
  brand: string;
  expiryMonth: number;
  expiryYear: number;
  zip?: string;
  complete?: boolean;
}

export interface InvoiceInfo {
  // http://developers.zef.fi/#backend:/docs/api/index.html#api-Billing-BillingHistory
  id: string;
  billingDate: Date;
  totalAmount: number;
  status: 'pending' | 'failed' | 'paid';
}

export interface InvoiceDetails extends InvoiceInfo {
  // http://developers.zef.fi/#backend:/docs/api/index.html#api-Billing-BillingHistoryInvoice
  totalWithoutVat: number;
  vatAmount: number;
  vatPercentage: number;
  periodStart: string;
  periodEnd: string;
  paymentMethod: string;
  cardBrand: string;
  payerName: string;
  payerAddressLine1?: string;
  payerAddressLine2?: string;
  payerAddressCity?: string;
  payerAddressZip?: string;
  payerAddressCountry?: string;
  payerVatId?: string;
  rows: {
    description: string;
    amount: number;
  }[];
}

export interface BillingCartRequest {
  subscription?: {
    id: PlanId;
    period: MonthlyYearly;
    options?: PlanCustomization[];
  };
  parked?: boolean;
  extra?: {
    answers?: {
      amount: number;
    };
    emails?: {
      amount: number;
    };
  };
  sms?: {
    id: string;
  };
  deleteFrom?: DeleteDataAnswer;
  cartDate: string;
}

export interface SMSPackage {
  id?: string;
  amount: number;
  price: number;
}

export interface SmsOptions {
  id: string;
  name: string;
  options: SMSPackage[];
}

export type SCATokenResponse = {
  scaToken?: string;
  pendingPayment?: number;
};
