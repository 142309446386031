<ng-container [ngSwitch]="role">
  <ng-container *ngSwitchCase="'owner'">
    <a class="zef-text-p2-link" routerLink="/settings/users">
      <strong class="zef-color-owner" i18n>Owner</strong>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="'admin'">
    <a class="zef-text-p2-link" routerLink="/settings/users">
      <strong class="zef-color-admin" i18n>Admin</strong>
    </a>
  </ng-container>

  <ng-container *ngSwitchCase="'member'">
    <span class="zef-text-p2 zef-color-member" i18n>Member</span>
  </ng-container>
</ng-container>
