<zef-dialog
  [type]="'ask'"
  [logo]="teamLogo$ | async"
  [logoType]="'square'"
  [title]="dialogTitle"
  [label]="(team$ | async)?.name || dialogLabel"
  [header]="dialogHeader"
  [action]="dialogActionConfirm"
  [disabled]="owner$ | async"
>
  <div dialog-content class="z-fx-col z-fx-gap-16 z-fx-flex">
    <div *ngIf="owner$ | async" class="avatar-box z-fx-sc z-fx-gap-16">
      <div
        class="zef-avatar-image small"
        [style.background-image]="
          (owner$ | async)?.avatar || 'assets/images/user-placeholder.png' | encodeURI: 'url' | safeStyle
        "
      ></div>

      <div class="z-fx-col z-fx-gap-4">
        <strong>{{ (owner$ | async)?.name }}</strong>
        <div>{{ (owner$ | async)?.email }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="(owner$ | async) === false" dialog-caption class="zef-text-p2-muted" i18n>
    An
    <user-role mat-dialog-close role="owner"></user-role>
    can review payment method
  </div>
</zef-dialog>
